import { getFirestore, getDoc, doc } from "firebase/firestore";

async function getPublicProfile(path) {
  if (!path) return null;
  const db = getFirestore();

  const docRef = doc(db, "linkProfiles", path);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    return data;
  } else {
    return {};
  }
}

export default { getPublicProfile };