import axios from "axios";
import firestore from "./firestore";

function getPublicProfile(userPath) {
  return axios
    .get(`https://api.${window.location.hostname}/api/v1/public/profile/${userPath}`, { timeout: 3000 })
    .then((res) => {
      return res.data;
    })
    .catch(async (err) => {
      return await firestore.getPublicProfile(userPath);
    });
}

export { getPublicProfile };
