import "./App.css";
import React, { useEffect, useState } from "react";
import { getPublicProfile } from "./api/public";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";

function logClick(url, cb) {
  const analytics = getAnalytics();
  logEvent(analytics, "click", {
    event_category: "outbound",
    event_label: url,
    transport_type: "beacon",
    event_callback: cb,
  });
}

function openTPSite() {
  const url = "https://teampeach.app/";
  const analytics = getAnalytics();
  logEvent(analytics, "tp_click", {
    event_category: "outbound",
    event_label: url,
    transport_type: "beacon",
    event_callback: () => window.open(url, "_blank"),
  });
}

function openLink(url) {
  logClick(url, () => {
    window.open(url, "_blank");
  });
}

function redirectLink(url) {
  window.location.href = url;
}

function getCurrentPathName() {
  return window.location.pathname.split("/").pop();
}

function App() {
  const [profile, setProfile] = useState({
    name: '\u00A0',
    linksList: [{url: '#', name: '\u00A0'}]
  });
  const [successfullyLoaded, setSuccessfullyLoaded] = useState(null);

  useEffect(() => {
    loadProfile();
  }, [successfullyLoaded]);

  async function loadProfile() {
    const path = getCurrentPathName();
    const profile = await getPublicProfile(path);

    if (profile === null) redirectLink("https://teampeach.app/");

    setProfile(profile);
    setSuccessfullyLoaded(profile ? true : false);
  }
  return (
    <>
      <div className="h-screen w-screen bg-gradient-to-r bg-gray-900 pt-3">
        {/* {successfullyLoaded === null ? (
          <div className=" flex justify-center items-center mx-3">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
          </div>
        ) : ( */}
          <div>
            <Helmet>
              <title>{profile?.name + " - Team Peach Links"}</title>
            </Helmet>
            <div className="mx-3 mt-10">
              <div className="flex-col px-6 bg-white shadow-lg rounded-3xl max-w-lg	place-self-center m-auto relative z-10">
                <div className="flex justify-center pt-4">
                  <div className="relative h-32 w-32 bg-gray-100 rounded-full" >
                    {profile.imageURL ? <img
                      className="flex h-32 w-32 rounded-full shadow-lg"
                      src={profile?.imageURL}
                      alt="Profile"
                    /> : null}
                    <div className="absolute top-0 right-0 h-7 w-7 my-1 border-2 border-white rounded-full bg-green-400 shadow-inner"></div>
                  </div>
                </div>
                <div className="pt-2 flex-col justify-center text-center">
                  <p className="font-medium	text-2xl pb-5 text-gray-600">
                    {profile?.name}
                  </p>
                </div>

                <div className="flex-col justify-center text-lg pb-2">
                  {profile?.linksList?.map(({ url, name }, index) => (
                    <button
                      key={url}
                      className="bg-gradient-to-r from-red-400 to-red-500 hover:bg-red-300 text-white font-bold py-3 px-auto w-full rounded-full mb-4 shadow-lg"
                      onClick={() => openLink(url)}
                    >
                      {name}
                    </button>
                  ))}
                </div>
              </div>
              <div
                onClick={() => openTPSite()}
                className="cursor-pointer	lex-col text-center text-base	font-light	text-white pt-3 z-10 relative"
              >
                Powered by - <b className="font-bold">Team Peach 🍑</b>
              </div>
            </div>
            <div className="">
              <img
                className="object-cover absolute top-0 left-0 h-screen w-screen filter-blur opacity-70 blur-2xl custom-blur"
                src={profile.imageURL}
                alt="Background"
              />
            </div>
          </div>
        {/* )} */}
      </div>
    </>
  );
}

export default App;
